<template>
    <div class="emp-summery">
        <template v-for="worker in workers" :key="worker.id">
            <div class="worker-card">
                <div class="worker-edit">
                    <i class="material-icons" style="color:white; cursor:pointer; margin-bottom: 10px;" @click="update_image(worker)">upload</i>
                    <i class="material-icons" style="color:white; cursor:pointer; margin-bottom: 10px;" @click="update_name(worker)">edit</i>
                    <i class="material-icons" style="color:white; cursor:pointer; margin-bottom: 10px; color:var(--danger);" @click="delete_worker(worker)">delete</i>
                </div>
                <div class="worker-image">
                    <img :src="worker.image" alt="">
                </div>
                <div class="worker-info">
                    <p>{{worker.name}}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { slide_pop_error, slide_pop_successs } from '../../Methods/Msgs'
import { projectFirestore, projectStorage } from '../../firebase/config'
export default {
props: ["workers"],
setup(){
    const update_name = async (worker) => {
        Swal.fire({
            icon: 'question',
            title: 'עדכון שם לעובד',
            text: 'הכנס שם חדש, שים לב: לא ניתן לעדן ת.ז במקרה של טעות יש למחוק את העובד ולהקים מחדש.',
            input: 'text',
            showCancelButton: true,
            cancelButtonText: 'ביטול',
            confirmButtonText: 'אישור'
        }).then(async (res) => {
            if(res.isConfirmed){
                if(res.value.length > 4){
                    await projectFirestore.collection('Workers').doc(worker.id).update({
                        name: res.value
                    }) 
                    slide_pop_successs("העובד עודכן בהצלחה!")
                }else{
                    slide_pop_error("אנא הכנס שם תקני, מינימום 4 תווים.")
                }
            }
        })
    }

    const delete_worker = async (worker) => {
        Swal.fire({
            icon: 'warning',
            title: 'מחיקת עובד',
            text: 'שים לב! העובד יימחק מהמערכת. אנא רשום את סיבת המחיקה',
            input: 'text',
            showCancelButton: true,
            cancelButtonText: 'ביטול',
            confirmButtonText: 'אישור'
        }).then(async (res) => {
            if(res.isConfirmed){
                if(res.value.length > 5){
                    await projectFirestore.collection('Workers').doc(worker.id).update({
                        active: false,
                        active_reason: res.value
                    }) 
                    slide_pop_successs("העובד עודכן בהצלחה!")
                }else{
                    slide_pop_error("נא למלא סיבת מחיקה תקנית")
                }
            }
        })
    }

    const update_image = async (worker) => {
        Swal.fire({
            icon: 'question',
            title: 'עדכן תמונת רופיל',
            text: 'התמונה הנוכחית של העובד תמחק לאלתר מהשרת.',
            input: 'file',
            showCancelButton: true,
            cancelButtonText: 'ביטול',
            confirmButtonText: 'אישור'
        }).then(async (res) => {
            if(res.isConfirmed){
                if(res.value && res.value.type){
                    slide_pop_successs("מעלה תמונה, אנא המתן!", 2500)
                    let upload = await projectStorage.ref(`Users/Images/${worker.id}.png`).put(res.value)
                    upload.ref.getDownloadURL().then(async url => {
                        await projectFirestore.collection('Workers').doc(worker.id).update({
                            image: url
                        }) 
                    })
                    slide_pop_successs("התמונה עודכנה בהצלחה!")
                }
            }
        })
    }

    return{
        update_name, delete_worker, update_image
    }
}
}
</script>

<style scoped>
.emp-summery{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns:repeat(auto-fill,minmax(150px, 1fr));
    grid-auto-rows: 150px;
    grid-gap: 5px;
    padding: 5px;
}
.worker-card{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.worker-image{
    width: 100%;
    height: 70%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.worker-card .worker-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(14, 12, 12, 0.589);
}
.worker-info{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 18px;
    color: var(--yellow);
}
.worker-edit{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>