<template>
    <div class="new-employee">
        <div class="title">
            <p>הוסף עובד חדש</p>
        </div>
        <div class="new-form">
            <div class="form-title">
                <i class="material-icons" style="margin-left: 5px;">person_outline</i>
                <p>שם מלא</p>
            </div>

            <el-input placeholder="מלא שם מלא בבקשה" v-model="new_worker.name" :input-style="input_style"></el-input>

            <div class="form-title">
                <i class="material-icons" style="margin-left: 5px;">fact_check</i>
                <p>ת.ז</p>
            </div>

            <el-input placeholder="נא לוודא את מס תעודת הזהות!" v-model="new_worker.id" :input-style="input_style"></el-input>

            <div class="form-title" style="margin-top:17px;">
                <i class="material-icons" style="margin-left: 5px;">groups</i>
                <p style="margin-left: 10px;">מחלקה</p>
                <el-select v-model="new_worker.category" placeholder="Select" style="width:120px;">
                    <el-option v-for="category in categories" :key="category" :label="category" :value="category"/>
                </el-select>
            </div>

            <div class="form-title" style="margin-top:10px;">
                <i class="material-icons" style="margin-left: 5px;">photo_camera</i>
                <p>תמונת פרופיל</p>
            </div>

            <div class="upload-image">
                <input type="file" name="" style="display:none;" id="upload" @change="put_in_storage">
                <div class="upload-box" @click="upload">
                    <i class="material-icons" style="color:white; cursor:pointer;" v-if="!new_worker.image">upload</i>
                    <img :src="new_worker.image" alt="" v-if="new_worker.image">
                </div>
            </div>

            <div class="note">
                <i class="material-icons" style="margin-left: 10px;">warning</i>
                <p>לפני לחיצה על אישור, אנא וודא כי כלל הפרטים נכונים!</p>
            </div>

            <el-button type="success" style="width:calc(100% - 10px); margin:auto; margin-bottom:5px;" @click="done">סיים</el-button>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore, projectStorage } from '../../firebase/config'
import { slide_pop_error, slide_pop_successs } from '../../Methods/Msgs'
export default {
emits:["close"],
setup(_, {emit}){

    const new_worker = ref({
        name: '',
        id: '',
        image: '',
        category: '',
        date_started: new Date(),
        total_tip_income: 0,
        active: true
    })

    const categories = ['מלצרים', 'ברמנים', 'הנהלה','שונות']

    const input_style = {
        'width': 'calc(100%  - 10px)',
        'margin-right': '5px',
        'background': 'none',
        'border': 'none',
        'border-bottom': '1px solid white',
        'color': 'white',
        'font-size': '18px',
        'text-align': 'center'
    }

    const upload = () => {
        if(new_worker.value.id && new_worker.value.name && new_worker.value.category){
            let el = document.getElementById('upload')
            el.click();
        }else{
            slide_pop_error("אנא מלא את כל השדות תחילה")
        }

    }

    const put_in_storage = async (e) => {
        if(e.target.files && e.target.files[0]){
            let file = e.target.files[0]
            slide_pop_successs("מעלה תמונה, אנא המתן!", 2500)
            let upload = await projectStorage.ref(`Users/Images/${new_worker.value.id}.png`).put(file)
            upload.ref.getDownloadURL().then(url => {
                new_worker.value.image = url
            })
            slide_pop_successs("התמונה הועלתה בהצלחה לשרת!")
        }
    }

    const done = async () => {
        if(new_worker.value.name && new_worker.value.id && new_worker.value.image && new_worker.value.category){
            await projectFirestore.collection('Workers').doc(new_worker.value.id).set(new_worker.value);
            slide_pop_successs("העובד נוצר בהצלחה!")
            emit("close");
            
        }else{
            slide_pop_error("אנא מלא נתונים חסרים!")
        }
    }

    return{
        new_worker, input_style, upload, put_in_storage, done, categories
    }
}
}
</script>

<style scoped>
.new-employee{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.25s;
}
.title{
    margin: 5px 0 0 0;
    width: 100%;
    background: var(--alert-purple);
    color: var(--yellow);
    padding: 5px;
    font-size: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.new-form{
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background: var(--alert-purple);
    overflow-y: auto;
}
.form-title{
    padding-right: 5px;
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 5px;
    flex-shrink: 0;
}
.upload-image{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 200px;
    align-items: center;
    padding: 10px;
}
.upload-box{
    width: 100%;
    height: 100%;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}
.upload-box i{
    font-size: 50px;
    transition: 0.25s;
}
.upload-box:hover i{
    font-size: 90px;
}
.upload-box img{
    max-width: 100%;
    max-height: 100%;
}
.note{
    width: 100%;
    padding: 5px 5px 5px 0;
    color: var(--danger);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
}
</style>