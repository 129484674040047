<template>
    <div class="employees">
        <div class="view-title">
            <i class="material-icons" style="font-size:45px;">people_alt</i>
            <p>ניהול עובדים</p>
            <div class="btns-row">
                <div class="control-btn" :style="check_state(null)" @click="state = null">
                    <i class="material-icons" style="margin-left: 5px;">people_alt</i>
                    <p>סיכום</p>
                </div>
                <div class="control-btn" :style="check_state('new')" @click="state = 'new'">
                    <i class="material-icons" style="margin-left: 5px;">group_add</i>
                    <p>הוסף חדש</p>
                </div>
            </div>
        </div>
        <div class="state-manager">
            <div class="summery" v-if="!state">
                <Summery :workers="workers"/>
            </div>
            <div class="new" v-if="state == 'new'">
                <NewEmp @close="state = null"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewEmp from '../../components/Employees/new.vue';
import Summery from '../../components/Employees/Summery.vue';
import { projectFirestore } from '../../firebase/config';
import { onUnmounted } from '@vue/runtime-core';

export default {
components:{NewEmp,Summery},
setup(){
    const state = ref(null)
    const workers = ref([]);

    const get_employees = projectFirestore.collection('Workers').where('active', '==', true).onSnapshot(snap => {
        snap.docChanges().forEach(change => {
            if(change.type == 'added'){
                workers.value.push(change.doc.data())
            }
            if(change.type == 'modified'){
                let inx = workers.value.findIndex(wrk => wrk.id == change.doc.data().id)
                if(inx != -1){
                    workers.value[inx] = change.doc.data()
                }
            }
            if(change.type == 'removed'){
                let inx = workers.value.findIndex(wrk => wrk.id == change.doc.data().id)
                if(inx != -1){
                    workers.value.splice(inx, 1)
                }
            }
        })
        workers.value = workers.value.sort((wr1, wr2) => {
            if(wr1.category > wr2.category){return 1}
            if(wr1.category < wr2.category){return -1}
            return 0
        })
    })

    const check_state = (test) => {
        if(state.value == test){
            return 'background:var(--success)'
        }else{
            return 'background:var(--purple)'
        }
    }

    onUnmounted(() => {
        get_employees();
    })

    return{
        state, check_state, workers
    }
}
}
</script>

<style scoped>
.employees{
    width: 100%;
    max-width: 750px;
    height: 100%;
    padding: 0 5px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
.view-title{
    width: 100%;
    height: 150px;
    background: var(--secondary);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    color: var(--yellow);
    justify-content: center;
    align-items: center;
}
.btns-row{
    width: 100%;
    height: 70px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    justify-content: flex-start;
}
.control-btn{
    width: fit-content;
    height: 70%;
    background: var(--purple);
    color: white;
    text-shadow: 0 0 3px black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0 5px 0 5px;
    border-radius: 5px;
    font-size: 24px;
    cursor: pointer;
    transition: 0.25s;
    margin-left: 10px;
    min-width: 100px;
}
.control-btn:hover{
    background: var(--success);
}
.state-manager{
    width: 100%;
    height: calc(100% - 155px);
    overflow:hidden;
    overflow-y: auto;
}
.summery{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .control-btn{
        min-width: unset;
        font-size: 18px;
    }
}
</style>